<template>
  <div class="home">
    <h1 class="title">Dashboard</h1>
    <template v-if="Data">
      <div class="notification is-success" v-if="Data.SendingXmasGifts">
        All orders including Xmas gifts are sending.
        <a v-on:click="sendingXmasGift(false)"
          >Click to stop fulfilling Xmas gifts</a
        >
      </div>
      <div class="notification is-danger" v-else>
        Xmas Gifts are currently <b>NOT</b> sending.
        <a v-on:click="sendingXmasGift(true)"
          >Click to enable sending fulfilling Xmas gifts</a
        >
      </div>
    </template>

    <div class="dashboard" v-if="Data">
      <table class="table">
        <tr>
          <th><b>FULFILMENT</b></th>
          <th>Last 24 hours</th>
          <th>Previous 24 hours</th>
          <th>Last 7 Days</th>
          <th>Previous 7 Days</th>
        </tr>
        <tr
          v-for="(value, propertyName) in Data.PeriodStats"
          :key="propertyName"
        >
          <td>{{ value.Name }}</td>
          <td>{{ value.Today }}</td>
          <td>{{ value.Yesterday }}</td>
          <td>{{ value.Last7Days }}</td>
          <td>{{ value.Previous7Days }}</td>
        </tr>
      </table>
      <br />
    </div>
    <h2 class="title is-4">Fulfilment</h2>

    <div class="dashboard" v-if="Data">
      <dashboard-count
        link="/fulfilment?status=3"
        section="Legacy vs New"
        label="Not Processed"
        title="Orders not decided if they are new or legacy fulfilment"
        :error-count="3"
        :amount="Data.FulfilmentCounts.SubscribersUnprocessed"
      ></dashboard-count>

      <dashboard-count
        link="/fulfilment?status=3"
        section="Fulfilled"
        label="Send Failed"
        title="Failed to send this order as part of a batch"
        :error-count="1"
        :amount="Data.FulfilmentCounts.SendFailed"
      ></dashboard-count>

      <dashboard-count
        link="/fulfilment?status=5"
        section="Fulfilled"
        label="Transform Failed"
        title="Unable to transform this order into the correct format"
        :error-count="1"
        :amount="Data.FulfilmentCounts.TransformFailed"
      ></dashboard-count>

      <dashboard-count
        link="/fulfilment?status=0"
        section="Fulfilled"
        label="Old Unfulfilled"
        title="Older than 7 days and not fulfilled"
        :warning-count="10"
        :error-count="20"
        :amount="Data.FulfilmentCounts.UnassignedOlder"
      ></dashboard-count>

      <dashboard-count
        link="/fulfilment?status=1"
        section="Fulfilled"
        label="Assigned Not Sent"
        title="Number of orders assigned to batchjob but the batchjob isn't sent"
        :amount="Data.FulfilmentCounts.AssignedNotSent"
      ></dashboard-count>

      <dashboard-count
        section="GDPR"
        label="Sync Failed"
        title="Failed to sync this with the GDPR database"
        :error-count="1"
        v-on:click.native="gdprRetry"
        :amount="Data.FulfilmentCounts.GDPRSyncFailed"
      ></dashboard-count>

      <dashboard-count
        section="GDPR"
        title="Not yet synced with GDPR database"
        label="Unsynced"
        :amount="Data.FulfilmentCounts.GDPRUnsynced"
      ></dashboard-count>

      <dashboard-count
        link="/fulfilment?fraudCheck=1"
        section="Fraud"
        title="Flagged orders of potential fraud"
        label="Flagged"
        :error-count="1"
        :amount="Data.FulfilmentCounts.FraudFlagged"
      ></dashboard-count>

      <dashboard-count
        link="/fulfilment?fraudCheck=0"
        section="Fraud"
        title="Orders not yet checked for fraud"
        label="Unchecked"
        :amount="Data.FulfilmentCounts.FraudUnchecked"
      ></dashboard-count>
    </div>
    <br />
    <h2 class="title is-4">Batch Jobs</h2>
    <div class="dashboard" v-if="Data">
      <dashboard-count
        link="/batch-jobs?status=1"
        section="Status"
        label="Not Sent"
        title="batch job created but not sent"
        :warning-count="1"
        :error-count="3"
        :amount="Data.BatchJobCount.CreatedNotSent"
      ></dashboard-count>
      <dashboard-count
        link="/batch-jobs?status=4"
        section="Status"
        title="batch job failed to send"
        label="Failed"
        :error-count="1"
        :amount="Data.BatchJobCount.Failed"
      ></dashboard-count>
    </div>
    <br />

    <h2 class="title is-4">Legacy</h2>
    <div class="dashboard" v-if="Data">
      <dashboard-count
        section="Legacy"
        label="Failed"
        title="failed to send orders onto Legacy Fulfilment"
        :error-count="1"
        :amount="Data.FulfilmentCounts.LegacyFailed"
      ></dashboard-count>

      <dashboard-count
        section="Legacy"
        label="Not Sent"
        title="Orders not sent to Legacy Fulfilment yet"
        :warning-count="1"
        :amount="Data.FulfilmentCounts.LegacyNotSent"
      ></dashboard-count>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import appSettings from "../variables.js";

export default {
  name: "home",
  components: {
    dashboardCount: () => import("../components/molecules/dashboardCount.vue"),
  },
  data: function () {
    return {
      APIUrl: process.env.VUE_APP_APIURL,
      Data: null,
    };
  },
  created: function () {
    this.loadData();
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.axios.get(this.APIUrl + "/dashboard").then((response) => {
        this.Data = response.data;
      });
    },
    gdprRetry() {
      var vm = this;
      this.axios
        .put(this.APIUrl + "/fulfilment/gdpr-failures/retry")
        .then((response) => {
          vm.loadData();
        });
    },
    sendingXmasGift(setTo) {
      var vm = this;
      this.axios
        .put(
          this.APIUrl +
            (setTo
              ? "/fulfilment/xmas-gifts-sending/"
              : "/fulfilment/xmas-gifts-stopping/")
        )
        .then((response) => {
          vm.loadData();
        });
    },
  },
};
</script>
<style>
.dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>